import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Components } from '@one/web-components';
import { EnclaveResponse } from 'src/app/shared/models/enclave';
import { AccessService, RolePermissionResponse } from 'src/app/shared/services/access.service';
import { EnclaveService } from 'src/app/shared/services/enclave.service';
import { environment as env } from 'src/environments/environment';
 
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent {
 
  @ViewChild('drawerElement', { static: true })
  drawerElement!: Components.OwcDrawer;
  isLoading: boolean = false;
  visible: boolean = true;
 
  constructor(
    protected readonly enclaveService: EnclaveService,
    private readonly router: Router,
    protected accessService: AccessService
  ) { }
 
  ngOnInit() {
    this.getEnclaveDetails()
  }
 
  getEnclaveDetails() {
    this.isLoading = true
    this.enclaveService.getEnclaveDetails().subscribe({
      next: async (response: EnclaveResponse) => {
        await this.updateUser(); 
        await this.getRolePermissions();
        this.enclaveService.enclaveDetails =  response.result;
        if(response.result.enclaveStatus !== env.ENC_CONF.STATUS_CONFIGURED ){
          this.router.navigate(['config']).then(()=>{
            this.isLoading = false;
          })
        }
        else {
          this.isLoading = false;
        }
      },
      error: (_error: EnclaveResponse) => {
        this.isLoading = false;
      },
      complete: () => {
      }
    })
  }
 
  getRolePermissions() :Promise<void>{
    return new Promise((resolve) => {
      this.accessService.getRolePermission().subscribe({
        next: (response: RolePermissionResponse) => {
          this.accessService.permissionDetails = response.result;
          resolve()
        },
        error: (_error) => {
          resolve()
        }
      })
    })
  }
 
  updateUser(): Promise<void> {
    return new Promise((resolve) => {
      this.enclaveService.updateUser().subscribe({
        next: (_resp)=> {
          resolve()
        },
        error:(_err) => {
          resolve()
        }
      })
    })
  }
 
 
}
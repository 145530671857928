import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from 'src/app/shared/services/config.service';
import { environment as env } from 'src/environments/environment';
import { AddExistingMemberPayload, DeleteMemberApi, EditMemberApi, EditMemberPayload, EditRoleApi, ExistingMemberApi, InviteNewMemberApi, MemberListApi,StudyBaseMemberListApi, type InviteNewMemberPayload } from '../models/study';
import {
  BucketDetailsResponse,
  InfraDetailsResponse,
  PositDetailsResponse
} from '../models/analytics';
import { 
  cloud9Response, 
  DataSet, 
  DatasetID, 
  DataSetPayload, 
  EC2InstanceSize, 
  GenerateKey, 
  GetJupyterInstance, 
  HTCFileSize, 
  SchemaListResponse,
  HTCFNodeInstance, 
  HTCInstance, 
  IngressFiles, 
  IngressFilesResponse, 
  IngressResponse, 
  JupyterInstancePayload, 
  MetaDataPayLoad, 
  MetaDataResponse, 
  NftPayLoad, 
  SageMakerProfile, 
  SageMakerResponse, 
  Schema, 
  SchemaResponse, 
  sFTPURL} from '../models/ingress';
import { ControlBase } from '../models/control-base';
import { MetadataDropdown } from '../models/metadata-dropdown';
import { Metadata } from '../models/metadata';
import { MetadataDateTime } from '../models/metadata-datetime';
import { MetadataTextbox } from '../models/metadata-textbox';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MetadataNumberbox } from '../models/metadata-numberbox';


@Injectable({
  providedIn: 'root'
})
export class EnclaveStudyDetailsService {
  masterSchema!: Schema;
  metaDataValidality = new BehaviorSubject(true);
  saveExitOrNextBtnClickInMetadata = new BehaviorSubject(false);
  isLoading: boolean = false;
  dataIngressFileUploadData = {};
  controls!: ControlBase<string>[];
  typeDetails:{"key":string,"type":string}[]=[];
  validators:any[] = [];

  constructor(
    private readonly configService: ConfigService,
    private http: HttpClient
  ) { }

  filterIngressByStatus(payload: DataSetPayload) {
    const url = this.configService.config['domain'] + env.ingressApi;
    const params = {
      status: payload.status,
      studyKey: payload.studyKey
    }
    return this.http.get<IngressResponse>(url , { params: params });
  }

  filterInboxByStatus(status: string | number, nextToken: string) {
    const category = 'DATASET-SUBMIT';
    const url = this.configService.config['domain'] + env.inbox +'/'+category;
    const params = new HttpParams()
      .set('size', '10')
      .set('nextToken', nextToken)
      .set('status', status)
    return this.http.get<IngressResponse>(url, { params: params });
  }

  saveInboxByStatus(requestPayLoad: any) {   
    const url = this.configService.config['domain'] + env.inbox ;
    return this.http.put<any>(url, requestPayLoad);
  }

  createDataSet(payload: DataSet) {
    const url = this.configService.config['domain'] + env.dataset;
    return this.http.post<DatasetID>(url, payload);
  }

  getDataset(id: string) {
    const url = this.configService.config['domain'] + env.dataset + '/' + id;
    return this.http.get<DatasetID>(url);
  }

  addFilesDataSet(payload: Partial<IngressFiles>[]) {
    const url = this.configService.config['domain'] + env.addFiles;
    return this.http.post<Partial<IngressFiles>[]>(url, payload);
  }

  removeFilesDataSet(payload: IngressFiles[]) {
    const url = this.configService.config['domain'] + env.addFiles;
    return this.http.delete(url, { body: payload});
  }

  getFilesDataSet(id: string) {
    const url = this.configService.config['domain'] + env.dataset + '/' + id + '/file';
    return this.http.get<IngressFilesResponse>(url);
  }

  getIngressSchema(enclaveKey: string) {
      //const url = "http://localhost:3000/enclave-base-data-ingestion-service/api/v1/schema"
    const url = this.configService.config['domain'] + env.ingressSchemaApi + '/' + enclaveKey;    
    return this.http.get<SchemaResponse>(url);
  }

  getsFTPDetails(email: string) {
    const url = this.configService.config['domain'] + env.sfTPDetails;
    const params = {
      "userEmail": email
    }
    return this.http.get<any>(url, { params: params });
  }

  getsFTPURL(studyKey: string, resourceKey: string) {
    const url = this.configService.config['domain'] + env.sFTPURL
    const params = {
      "studyKey": studyKey,
      'resourceKey': resourceKey
    }
    return this.http.get<sFTPURL>(url, { params: params });
  }


  getMetaDataDetail(datasetId : string | null) {
    const url = this.configService.config['domain'] + env.dataIngressGetMetadata + '/' + datasetId + '/metadata';
    return this.http.get<MetaDataResponse>(url);
  }

  createMetaData(payload:MetaDataPayLoad) {
    const url = this.configService.config['domain'] +env.saveIngress;
    return this.http.post<MetaDataResponse>(url, payload);
  }
  updateMetaData(payload:MetaDataPayLoad,schemaId:string) {
    const url = this.configService.config['domain'] +env.updateMetaSchemas+"/"+schemaId;
    return this.http.put<MetaDataResponse>(url, payload);
  }
  anaylyticsInstances(id:string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/"+id+"/analytics/ondemand/instance";
    return this.http.get<IngressResponse>(url);
  }

  inviteNewMember(payload:InviteNewMemberPayload){
    const url = this.configService.config['domain'] + env.inviteNewMemberApi;
    return this.http.post<InviteNewMemberApi>(url, payload);
  }

  addExistingMember(payload:AddExistingMemberPayload[]){
    const url = this.configService.config['domain'] + env.inviteExistingMemberApi;
    return this.http.post<ExistingMemberApi>(url, payload);
    
  }
  getMemberDetailsByEmail(email:string){
    const url = this.configService.config['domain'] + env.memberDetailsByEmailApi;
    const params = {
      "userEmail":email
    }
    return this.http.get<IngressResponse>(url , { params: params });
  }

  viewMemberList(studyKey:string,size:number,nextToken:string){
    const url = this.configService.config['domain'] + env.viewMemberApi;
    const params = {
      "studyKey":studyKey,
      "size":size,
      "nextToken":nextToken
    }
    return this.http.get<MemberListApi>(url,{ params: params });
  }

  editMember(payload:EditMemberPayload){
    const url = this.configService.config['domain'] + env.editMemberApi;
    return this.http.put<EditMemberApi>(url, payload);
    
  }

  getMembersDetailList(studyKey:string){
    const url = this.configService.config['domain']  + env.membersListApi + "/" + studyKey;
    return this.http.get<StudyBaseMemberListApi>(url);
  }

  removeMember(id:{id:string}){
    const url = this.configService.config['domain'] + env.editMemberApi;
    return this.http.delete<DeleteMemberApi>(url, { body: id});
    
  }
  getexcludedCurrentRoleList(roleName:string){
    const url = this.configService.config['domain'] + env.excludeCurrentroleApi;
    const params = {
      "roleName":roleName
    }
    return this.http.get<EditRoleApi>(url,{ params: params });
  }

  getRoleListByRoleType(roleType:string){
    const url = this.configService.config['domain'] + env.getRoleByType +'/'+roleType;
    return this.http.get<EditRoleApi>(url);
  }



  getSagmakerUserProfile(id:string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/"+id+"/analytics/sagemakeruserprofile"; 
    return this.http.get<SageMakerResponse>(url);
  }

  getCloud9(id:string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/"+id+"/analytics/cloud9"; 
    return this.http.get<SageMakerResponse>(url);
  }

  getInfraDetail(id: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/"+id+"/analytics/infradetail"; 
    return this.http.get<InfraDetailsResponse>(url);
    
  }

  gethpcInstanceSize() {
    const url = this.configService.config['domain'] + env.instarnceSize+"/hpcInstanceSize/contents"; 
    return this.http.get<HTCFileSize>(url);
  }

  getec2InstanceSize() {
    const url = this.configService.config['domain'] + env.instarnceSize+"/ec2InstanceSize/contents"; 
    return this.http.get<EC2InstanceSize>(url);
  }

  
  gethjupyterInstanceSize() {
    const url = this.configService.config['domain'] + env.instarnceSize+"/jupyterInstanceSize/contents"; 
    return this.http.get<GetJupyterInstance>(url);
  }
 
  getPosit(id: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + id + "/analytics/positurl";
    return this.http.get<PositDetailsResponse>(url);
  }

  getStudyBucket(id: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/"+id+"/analytics/studybucketdetails"; 
    return this.http.get<BucketDetailsResponse>(url);
  }

  saveJupyterInstance(payload: JupyterInstancePayload) {
    const url = this.configService.config['domain'] +env.analyticsInstance + "/analytics/jupyternotebook";
    return this.http.post<MetaDataResponse>(url, payload);
  }

  saveEc2Instance(payload: JupyterInstancePayload) {
    const url = this.configService.config['domain'] +env.analyticsInstance + "/analytics/ec2instance";
    return this.http.post<MetaDataResponse>(url, payload);
  }

  getJupterInstance(instanceId: string, studyKey: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/"+studyKey+"/analytics/launch/jupyternotebook/"+instanceId; 
    window.open(url, '_blank');
  }

  getEc2Instance(instanceId: string, studyKey: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + studyKey + "/analytics/launch/ec2instance/" + instanceId; 
    window.open(url, '_blank');
  }

  getGenerateKey(studyKey: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + studyKey + "/analytics/cloud9/credentials";
    return this.http.get<GenerateKey>(url);
  }

  postNftLogin(email: NftPayLoad) {
    const url = this.configService.config['domain'] + env.nftLogin;
    return this.http.post<cloud9Response>(url, email);
  }
  
  getLaunchSageMaker(studyKey: string, appName: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + studyKey + "/analytics/launch/"+appName+"/sagemakeruserprofile";
    window.open(url, '_blank');
  }

  saveSageMakerProfile(payload: SageMakerProfile) {
    const url = this.configService.config['domain'] + env.analyticsInstance +"/analytics/sagemakeruserprofile";
    return this.http.post<SageMakerResponse>(url, payload);
  }

  terminateInstance(studyKey: string, instanceId : string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + studyKey + "/analytics/instance/" + instanceId;
    return this.http.delete<SageMakerResponse>(url);
  }

  saveCloud9(payload: SageMakerProfile){
    const url = this.configService.config['domain'] + env.analyticsInstance +"/analytics/cloud9";
    return this.http.post<SageMakerResponse>(url, payload);
  }

  getCloud9Launch(studyKey: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + studyKey + "/analytics/launch/cloud9";
    window.open(url, '_blank');
  }

  getHpcInstanceDetails(studyKey: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/analytics/hpcinstancedetails/" + studyKey;
    return this.http.get<HTCFNodeInstance>(url);
  }

  getHpcLaunch(studyKey: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + studyKey + "/analytics/launch/hpc";
    window.open(url, '_blank');
  }

  submitMetadata(payload:any){
    const url = this.configService.config['domain'] + env.dataIngressSubmit;
    return this.http.post<any>(url, payload);

  }

  saveHPCData(studyKey: string, instanceId: string, action:string) {
    let payload = {};
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + studyKey + "/manageInstance/"+action+"/"+ instanceId ;
    return this.http.post<any>(url, payload);
  }

  getAllInstanceSizeList(instanceType: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/instancetypes/"+instanceType; 
    return this.http.get<HTCFileSize>(url);
  }

  getSchemaList(){
    const url = this.configService.config['domain'] + env.getSchemaList;
    return this.http.get<SchemaListResponse>(url);
  }
  getMetadataSchemaByGroup(schemaIdentifier:string,schemaType:string){
   const url = this.configService.config['domain'] + env.getSchemaData+"/"+schemaIdentifier+"/"+schemaType; 
   return this.http.get<any>(url);
  }

  typeBasedObject(controlNameKey:string,listEnum: { enum: any; label?: any; value: any; hidden: any; readonly: any; displayOrder: any; },jsonValue: { required: string | string[]; },listoreder: number){
    return {
      formControlName: controlNameKey,
      label: listEnum.label?listEnum.label:controlNameKey,
      placeholder: listEnum.label?listEnum.label:controlNameKey,
      options: listEnum.enum,
      value: listEnum.value?listEnum.value:'',
      required: jsonValue.required.includes(controlNameKey),
      order: 1,
      hidden: listEnum.hidden ? listEnum.hidden : false,
      readonly: listEnum.readonly ? listEnum.readonly : false,
      displayOrder: listEnum.displayOrder ? listEnum.displayOrder : listoreder
    }
  }

  createFieldByType(controlNameKey: string, controlType: { type: any; }, listoreder: number, jsonValue: any) {
    switch (controlType.type) {
      case 'string':
        {
          this.controls.push(new MetadataTextbox(this.jsoncontrolTypeObject(controlNameKey, controlType, listoreder, jsonValue, 'text')));
          break;
        }
        case 'number':
        {
          this.controls.push(new MetadataNumberbox(this.jsoncontrolTypeObject(controlNameKey, controlType, listoreder, jsonValue, 'number')));
          break;
        }
      case 'elnLookup':
        {
          this.controls.push(new MetadataTextbox(this.jsoncontrolTypeObject(controlNameKey, controlType, listoreder, jsonValue, 'elnLookup')));
          break;
        }
      case 'date':
        {
          this.controls.push(new MetadataDateTime(this.jsoncontrolTypeObject(controlNameKey, controlType, listoreder, jsonValue, 'dateTime')));
          break
        }
    }
  }
  
  jsoncontrolTypeObject(controlNameKey: string,controlType: { type?: any; label?: any; value?: any; pattern?: any; hidden?: any; readonly?: any; displayOrder?: any; minLength?:number,maxLength?:number},listoreder: number,jsonValue: { required: string | any[]; },type: string){
    return {
     formControlName: controlNameKey,
     label: controlType.label ? controlType.label :controlNameKey,
     placeholder: controlType.label ? controlType.label :controlNameKey,
     value: controlType.value ? controlType.value :'',
     required: jsonValue.required.includes(controlNameKey),
     type,
     order: 1,
     pattern: controlType.pattern ? controlType.pattern : null,
     hidden: controlType.hidden ? controlType.hidden : false,
     readonly: controlType.readonly ? controlType.readonly : false,
     displayOrder: controlType.displayOrder ? controlType.displayOrder : listoreder,
     minLength: controlType.minLength,
     maxLength: controlType.maxLength
   }
   }
  readJSON(jsonValue:any): Metadata {
    const listoreder = 9999;  
    this.controls = [];
    for(const controlNameKey in jsonValue.properties) {
      for (const typeKey in jsonValue.properties[controlNameKey]) {
        switch (typeKey) {
          case 'enum': {
            const listEnum = jsonValue.properties[controlNameKey];
            this.controls.push(new MetadataDropdown(this.typeBasedObject(controlNameKey,listEnum,jsonValue,listoreder)));
            break;
          }

          case 'type': {
            const controlType = jsonValue.properties[controlNameKey];
            this.createFieldByType(controlNameKey, controlType, listoreder, jsonValue)
            break;
          }
        }
      }
    }
    
     return new Metadata('', this.controls);
  }

  toFormGroup(controls: ControlBase<string>[]) {
    const group: any = {};
    this.typeDetails = [];
    controls.sort((a, b) => {
      return a.displayOrder - b.displayOrder;
    });
  controls.forEach(control => {
       const dynamicallyAddedValidators = this.getValidators(control);

       const valueObject={"key":control.formControlName,"type":control.type};
       this.typeDetails.push(valueObject);

       if (dynamicallyAddedValidators.length >= 1) {
        group[control.formControlName] = new FormControl({ value: control.value || '', disabled: control.readonly ? true : false }, dynamicallyAddedValidators);
      
        
       }
       else {
        group[control.formControlName] = new FormControl({ value: control.value || '', disabled: control.readonly ? true : false });
     
       }
    });
    return new FormGroup(group);
  }
 

  getValidators(control: ControlBase<string>){

    this.validators= [];
          if (control.pattern) {
          this.validators.push(Validators.pattern(control.pattern));
          }
          if (control.minimum) {
            this.validators.push(Validators.min(control.minimum));
          }
          if (control.maximum) {
            this.validators.push(Validators.max(control.maximum));
          }
          if (control.required) {
            this.validators.push(Validators.required);
          }
          if (control.minLength) {
            this.validators.push(Validators.minLength(control.minLength));
          }
          if (control.maxLength) {
            this.validators.push(Validators.maxLength(control.maxLength));
          }
          // if (control.type == 'dateTime') {
          //   this.validators.push(ValidateTime)
          // }
    return this.validators;
  }
}
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { CoreModule } from './core/core.module';
import { AppInitService } from './app-init.service';
import { ConfirmationPopupWithoutSpinnerComponent } from './shared/component/confirmation-popup-without-spinner/confirmation-popup-without-spinner.component';
import { ConfirmationPopupWithInputComponent } from './shared/component/confirmation-popup-with-input/confirmation-popup-with-input.component';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './router/custom-router-reuse-strategy';
import { ImageModalViewComponent } from './shared/component/image-modal-view/image-modal-view.component';

export function initializeAppConfig(appInitService: AppInitService) {
  return (): Promise<void> => { 
    return appInitService.loadConfiguration();
  }
}

export function initializeLabelConfig(appInitService: AppInitService) {
  return (): Promise<void> => { 
    return appInitService.loadLabels();
  }
}


@NgModule({
  declarations: [
    AppComponent,
    ConfirmationPopupWithoutSpinnerComponent,
    ConfirmationPopupWithInputComponent,
    ImageModalViewComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    AuthModule,
    AppRoutingModule,
    DashboardModule
    
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppConfig,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLabelConfig,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

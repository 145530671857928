import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Components } from '@one/web-components';

interface DialogData {
  HEADER: string,
  DESC: string[]
  CONFIRM_BTN?: string,
  CANCEL_BTN?: string,
  FOOTER_TEXT?: string,
  VALIDATION_TEXT?: string,
  ERRORMSG?: ErrorMessage
}

interface ErrorMessage{
  REQUIRED_FIELD:string,
  MAX_LENGTH_75 :string,
  ASSISTIVE_TEXT_STUDY_NAME_PATTERN:string
}

@Component({
  selector: 'app-confirmation-popup-with-input',
  templateUrl: './confirmation-popup-with-input.component.html',
  styleUrls: ['./confirmation-popup-with-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmationPopupWithInputComponent {
  @ViewChild('typographyElement', { static: true }) typographyElement!: Components.OwcTypography;
  @ViewChild('progressSpinnerElement', { static: true }) progressSpinnerElement!: Components.OwcProgressSpinner;
  studyPattern = "^[a-zA-Z0-9]*$";
  studyCreateForm = new FormGroup({    
      studyName: new FormControl(''),
    })
  
  visible: boolean = true;
  constructor(@Inject(DIALOG_DATA) protected data: DialogData, public dialogRef: DialogRef<boolean>) {     
   }
  onDecline() {
    this.dialogRef.close(false);
  }

  onAddFieldKey() {
    this.studyCreateForm.get('studyName')?.setValidators([Validators.required, Validators.pattern(this.studyPattern)]);
    this.studyCreateForm.get('studyName')?.updateValueAndValidity();
    if (this.studyCreateForm.valid) {
      this.data.HEADER = this.studyCreateForm.get('studyName')?.value || "";
      this.dialogRef.close(true);
    }
  }
}

export class ControlBase<T> {
    value;
    formControlName: string;
    label: string;
    placeholder: string;
    required: boolean;
    order: number;
    controlType: string;
    type: string;
    options: string[];
    pattern: string;
    hidden: boolean;
    readonly: boolean;
    minimum: number;
    maximum: number;
    keepAsterisk: boolean;
    displayOrder: number;
    minLength: number;
    maxLength: number;
    /* checked: boolean; */

    constructor(options: {
        value?: any;
        formControlName?: string;
        label?: string;
        placeholder?: string;
        required?: boolean;
        order?: number;
        controlType?: string;
        type?: string;
        pattern?: string;
        options?: string[];
        hidden?: boolean;
        readonly?: boolean;
        minimum?: number;
        maximum?: number;
        checked?: boolean;
        displayOrder?: number;
        minLength?: number;
        maxLength?: number;
    } = {}) {
        this.value = options.value;
        this.formControlName = options.formControlName || '';
        this.label = options.label || '';
        this.placeholder = options.placeholder || '';
        this.required = !!options.required;
        this.order = options.order === undefined ? 1 : options.order;
        this.controlType = options.controlType || '';
        this.type = options.type || '';
        this.options = options.options || [];
        this.pattern = options.pattern!;
        this.hidden = options.hidden || false;
        this.readonly = options.readonly || false;
        this.minimum = options.minimum!;
        this.maximum = options.maximum!;
        this.keepAsterisk = true;
        this.displayOrder = options.displayOrder!;
        this.minLength = options.minLength!;
        this.maxLength = options.maxLength!;
    }
}

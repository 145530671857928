import { ControlBase } from './control-base';

export class Metadata {
    name: string;
    controls: ControlBase<string>[];
    isFormInValid: boolean;
    constructor(name: string, controls: ControlBase<string>[], isFormInValid: boolean = false) {
        this.name = name;
        this.controls = controls;
        this.isFormInValid = isFormInValid;
    }
}
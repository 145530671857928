import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { environment as env} from 'src/environments/environment';
import { Enclave, EnclaveResponse } from '../models/enclave';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EnclaveService {
  enclaveDetails!: Enclave;
  constructor(
    private readonly configService: ConfigService,
    private http: HttpClient
  ) { }

  getEnclaveDetails(){
    const url = this.configService.config['domain'] + env.enclaveDetails
    return this.http.get<EnclaveResponse>(url);
  }

  saveEnclaveDetails(id: string, payload: Enclave){
    const url = this.configService.config['domain'] + env.enclavePutAPI + '/' + id
    return this.http.put<EnclaveResponse>(url, payload);
  }

  isConfigured(){
    return this.enclaveDetails.enclaveStatus.toLowerCase() 
                    === env.ENC_CONF.STATUS_CONFIGURED.toLowerCase() 
  }

  updateUser() {
    const url = this.configService.config['domain'] + env.updateUser 
    return this.http.post(url, {});
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guard/auth.guard';
import { HomeComponent } from './dashboard/components/home/home.component';
import { AppLobbyComponent } from './dashboard/components/app-lobby/app-lobby.component';
import { UnauthorizedComponent } from './dashboard/components/unauthorized/unauthorized.component';
import { appPermissionGuard } from './auth/guard/app-permission.guard';
import { StudySavedComponent } from 'projects/enclave-studies/src/app/components/studies/study-saved/study-saved.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [appPermissionGuard],
        children: [
          {
            path: '',
            component: AppLobbyComponent
          },
          {
            path: 'config',
            loadChildren: () => import('../../projects/enclave-config/src/app/app.module').then(m => m.AppModule),
          },
           {
            path: 'studies',
            loadChildren: () => import('../../projects/enclave-studies/src/app/app.module').then(m => m.AppModule),
            data: {
              breadcrumb: 'Studies'
            }
          },
          {
            path: 'studies/:id',
            loadChildren: () => import('../../projects/enclave-studies/src/app/app.module').then(m => m.AppModule),
          }
        ]
      },
      {
        path: 'study-saved',
        component: StudySavedComponent
      },
       {
        path: 'u',
        component: UnauthorizedComponent,
      }
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

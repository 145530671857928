import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { SelectionTableDataEntry } from 'projects/enclave-studies/src/app/models/ingress';
import { LabelService } from '../../services/label.service';

@Component({
  selector: 'app-image-modal-view',
  templateUrl: './image-modal-view.component.html',
  styleUrls: ['./image-modal-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageModalViewComponent {
  i18n = this.labelService.labels;
  constructor(@Inject(DIALOG_DATA) protected data: SelectionTableDataEntry, 
  private readonly labelService: LabelService,
  public dialogRef: DialogRef<boolean>){}
  loadingIndicate:boolean=true;
  onAccept() {
    this.dialogRef.close(true);
  }
  onDecline() {
    this.dialogRef.close(false);
  }
  onLoad(){
    this.loadingIndicate = false;
  }
  // onError(){
  //   this.loadingIndicate = false;
  //   this.data.imageURL = '/assets/images/placeholder.png';
  // }
}

export const environment = {
    production: false,
    apiProfile: '/api/v1/users/whoami',
    apiLogout: '/api/v1/auth/logout',


    PERMISSIONS:  {
        STUDIES_APP: "study_management.studies_list",
        CONF_APP: "enclave_config.view_config",
        INBOX_APP: "inbox.view_inbox",
        DATA_INGRESS: "data_ingestion.view_ingress_module",
        DATA_CATALOG: "data_catalog.view_catalog_module",
        //Studies
        CREATE_STUDY: "study_management.create_study",
        STUDY_DETAILS: "study_details.view_study_details",

        //Data Ingestion
        DATA_INGRESS_UPLOAD_FILE:"data_ingestion.upload_files",

        //Analytics
        STUDY_ANALYTICS: "study_analytics.view_analytics_module",
        STUDY_ANALYTICS_ACCESS_HPC: "study_analytics.access_hpc",
        STUDY_ANALYTICS_MANAGE_EC2_JPY_SELF: "study_analytics.manage_ec2_jupyter_instance_self",
        STUDY_ANALYTICS_MANAGE_EC2_JPY_OTHERS: "study_analytics.manage_ec2_jupyter_instance_others",
        STUDY_ANALYTICS_TERMINATE_INSTANCE: "study_analytics.terminate_ec2_jupyter_others",
        STUDY_ANALYTICS_SAGEMAKER: "study_analytics.access_sagemaker_apps",
        STUDY_ANALYTICS_CLOUD9: "study_analytics.access_cloud9",
        STUDY_ANALYTICS_NFT_MAINTAIN: "study_analytics.nft_maintain_role",
        STUDY_ANALYTICS_NFT_LAUNCH: "study_analytics.nft_launch_role",
        STUDY_ANALYTICS_HO_CREATE: "study_analytics.health_omics_create_run_workflow",
        STUDY_ANALYTICS_HO_RUN: "study_analytics.health_omics_run_workflow",
        STUDY_ANALYTICS_POSIT: "study_analytics.access_posit",
        STUDY_ANALYTICS_HALO: "study_analytics.access_halo",
        STUDY_ANALYTICS_MANAGE_HPC: "study_analytics.manage_hpc",
        STUDY_ANALYTICS_EC2_LARGE_SIZE:"study_analytics.ec2_large_size_instance",
        //Members
        STUDY_MEMBERS: "study_members.view_members",
        STUDY_MEMBERS_INVITE: "study_members.invite_member",
        STUDY_MEMBERS_EDIT:"study_members.edit_member",
        STUDY_MEMBERS_REMOVE: "study_members.remove_member",
        
        //catalog
        CATALOGUE_SEARCH: "data_catalog.search_catalog",
        CATALOGUE_EDIT_METADATA:"data_catalog.edit_metadata"
    },
    ENC_ADMIN: 'ADMIN',
    ENC_CONF: {
        NFT: 'nft',
        ANALYTICS: 'analytics',
        COMPUTE: 'compute',
        ENC_BASE: 'enclave_base',
        STATUS_CONFIGURED: 'CREATED',
        STATUS_INPROGRESS: 'INPROGRESS',
        STATUS_NOT_CONFIGURED: 'NO',
        STATUS_FAILED:'FAILED'
    },
    STUDY: {
        STATUS_CREATED: 'CREATED',
        STATUS_INPROGRESS: 'INPROGRESS',
        STATUS_FAILED: 'FAILED'
    },

    // enclave-base-management-service
    enclaveDetails: 'enclave-base-management-service/api/v1/enclave',
    enclavePutAPI: 'enclave-base-management-service/api/v1/enclaves',
    configApi: 'enclave-base-management-service/api/v1/enclave/appconfig/config',
    featureApi: 'enclave-base-management-service/api/v1/enclave/feature',
    deleteFeatureApi: 'enclave-base-management-service/api/v1/enclave/feature',
    permissionsAPi: 'enclave-base-user-management-service/api/v1/enclave/permissions',
    rolesApi: 'enclave-base-user-management-service/api/v1/enclave/role',
    ingressApi: 'enclave-base-data-ingestion-service/api/v1/datasets',
    downloadSchema: 'enclave-base-data-ingestion-service/api/v1/schema/download/template',
    downloadTemplate: 'enclave-base-data-ingestion-service/api/v1/schema/download/default',
    updateUser: 'enclave-base-user-management-service/api/v1/enclave/user/entry',
    
    //study
    studyApi: "enclave-base-study-management-service/api/v1/study",
    validateStudyApi: "enclave-base-study-management-service/api/v1/study/validate",
    ingressSchemaApi:"enclave-base-data-ingestion-service/api/v1/schema",
    nftLogin: "enclave-base-study-management-service/api/v1/nftlogin",
    sFTPURL: "enclave-base-study-management-service/api/v1/study/infra/resourcedetail/",

    // Data Ingress
    dataset: 'enclave-base-data-ingestion-service/api/v1/dataset',
    addFiles: 'enclave-base-data-ingestion-service/api/v1/dataset/file',
    listFiles: "enclave-base-data-ingestion-service/api/v1/sftpfiles",
    dataIngressFileUpload: 'enclave-base-data-ingestion-service/api/v1/filemanagement/upload',
    dataIngressCompleteMultiPart: 'enclave-base-data-ingestion-service/api/v1/filemanagement/completeupload',
    saveIngress: "enclave-base-data-ingestion-service/api/v1/dataset/metadata",
    getSchemaList: "enclave-base-data-ingestion-service/api/v1/schemas",
    getSchemaData: "enclave-base-data-ingestion-service/api/v1/schema",
    updateMetaSchemas:"enclave-base-data-ingestion-service/api/v1/schema/nodeschemas",
    dataIngressGetMetadata:"enclave-base-data-ingestion-service/api/v1/dataset",
    dataIngressSubmit:"enclave-base-data-ingestion-service/api/v1/dataset/submit",
    inbox:"enclave-base-data-ingestion-service/api/v1/inbox",
    analyticsInstance: "enclave-base-study-management-service/api/v1/study",
    instarnceSize: "enclave-base-management-service/api/v1/enclave/config",
    
    // dataIngressFailedNotificationApi: 'enclave-base-data-ingestion-service/api/v1/filemanagement/completeupload',

    //Members
    inviteNewMemberApi:"enclave-base-user-management-service/api/v1/enclave/user/invite",
    inviteExistingMemberApi:"enclave-base-user-management-service/api/v1/enclave/users",
    memberDetailsByEmailApi:"enclave-base-user-management-service/api/v1/email",
    viewMemberApi:"enclave-base-user-management-service/api/v1/enclave/study/members",
    membersListApi:"enclave-base-user-management-service/api/v1/users/ENCLAVE",
    editMemberApi:"enclave-base-user-management-service/api/v1/enclave/user",
    excludeCurrentroleApi:"enclave-base-user-management-service/api/v1/enclave/study/role",
    getRoleByType:"enclave-base-user-management-service/api/v1/enclave/role/roleType",
    
    //rolePermissions
    rolePermissions: "enclave-base-user-management-service/api/v1/enclave/user/rolepermission",

    //sFTP
    sfTPDetails: "enclave-base-security-service/api/v1/detail",

    //catalogue
    catalogueDetails: "enclave-base-catalog-service/api/v1/publish/customsearch"
};

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LabelService } from 'src/app/shared/services/label.service';


@Component({
  selector: 'app-study-saved',
  templateUrl: './study-saved.component.html',
  styleUrls: ['./study-saved.component.scss']
})
export class StudySavedComponent {
  @Output() close = new EventEmitter()
  i18n = this.labelService.labels;
  @Input() from!: string;
  constructor(private readonly labelService: LabelService, private router : Router){}

  studySaved() {
    this.close.emit()
  }
}

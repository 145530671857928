import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { environment as env} from 'src/environments/environment';
import { ResponseBase } from '../models/enclave';

export interface RolePermission {
  userEmail: string;
  enclaveAdmin: boolean;
  commonPermission: string[];
  studyUserRole:{
      [k:string]: string;
  }
  rolePermission: {
      [k:string]: string[];
  }
}

export interface RolePermissionResponse extends ResponseBase {
  result : RolePermission
}

const COMMON_CATEGORY = 'commonPermission';

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  permissionDetails!: RolePermission;
  constructor(
    private readonly configService: ConfigService,
    private readonly http: HttpClient
  ) { }

  isAdmin(){
    return this.permissionDetails.enclaveAdmin
  }

  checkAllPermission(access: string[], roleName: string = COMMON_CATEGORY) {
    const accessObj: { [k: string]: boolean } = {};
    if (this.isAdmin()) {
      access.forEach((item: string) => {
        accessObj[item] = true;
      })
    }
    else {
      access.forEach((item: string) => {
        accessObj[item] = this.checkPermission(item, roleName);
      })
    }
    return accessObj
  }

  checkPermission(accessName: string, roleName: string = COMMON_CATEGORY){
    if(roleName === COMMON_CATEGORY){
      return this.permissionDetails[COMMON_CATEGORY].includes(accessName)
    }
    else {
      return this.permissionDetails.rolePermission[roleName].includes(accessName)
    }
  }

  getCurrentStudyRole(studyId: string){
    return this.permissionDetails['studyUserRole'][studyId]
  }

  getRolePermission(){
    const url = this.configService.config['domain'] + env.rolePermissions
    return this.http.get<RolePermissionResponse>(url);
  }


}

<div class="image-viewer-popup">
    <owc-modal-dialog #modalDialogElement disable-backdrop-click [visible]="true">
        <div class="overlay-header"> 
            <span class="header-title">{{i18n.IMAGE_POPUP.HEADER}}</span>
            <owc-icon-button (click)="onDecline()" [icon]="'clear'"></owc-icon-button>
            
        </div>
        <div class="modal-content">
            <owc-typography class="heading">
                {{data.keyname}} 
            </owc-typography>
        </div>
        <div>
            <span class="loadSpinner" *ngIf="(loadingIndicate && data?.imageURL)">
                <owc-progress-spinner [size]="'small'" [value]="50" [variant]="'secondary'" [value]="50" />
            </span>
            <owc-icon class="images-layout" *ngIf="data?.imageURL">
                <img (load)="onLoad()" [hidden]="loadingIndicate" [src]="data?.imageURL" alt="img" class="full-view"/>
            </owc-icon>
            <span *ngIf="!(data?.imageURL)">{{i18n.IMAGE_POPUP.ERROR}}</span>
        </div>
        <owc-component-footer slot="footer" >
            <div slot="suffix" class="modal-footer">
            <owc-button (click)="onAccept()" class="margin-left-16">{{i18n.IMAGE_POPUP.CLOSE_BTN}}</owc-button>
            </div>
        </owc-component-footer>
    </owc-modal-dialog>
</div>

import { Component, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Components } from '@one/web-components';
import { SideMenu } from 'projects/enclave-studies/src/app/models/study';
import { AccessService } from 'src/app/shared/services/access.service';
import { EnclaveService } from 'src/app/shared/services/enclave.service';
import { LabelService } from 'src/app/shared/services/label.service';
import { environment as env } from 'src/environments/environment';

const accessList = [
  env.PERMISSIONS.STUDIES_APP,
  env.PERMISSIONS.CONF_APP,
  env.PERMISSIONS.INBOX_APP,
  env.PERMISSIONS.DATA_CATALOG,
]

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
  @ViewChild('listElement', { static: true }) listElement!: Components.OwcList;
  i18n = this.labelService.labels;
  menuExpanded: boolean = false;
  selectedMenu: (string | number)[] = [this.i18n.SIDE_MENU.DASHBOARD]; 
  readonly accessDetails = this.accessService.checkAllPermission(accessList)
  constructor (
    private readonly labelService: LabelService,
    private readonly enclaveService: EnclaveService,
    private readonly route : Router,
    private readonly accessService: AccessService
  )
  {  }

  ngOnInit() {
    this.updateHeader(this.route.url);
    this.route.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        const paths = event.url.split('/')
        this.updateHeader(paths[1])
      }
    });
  }

  updateHeader(url:string) {
    this.menuItems.forEach((sideMenu: SideMenu )=> {
      if (url.includes(sideMenu.route)) {
        this.selectedMenu = [sideMenu.name];
      } 
    })
  }

  menuItems: SideMenu []= [
    {
      icon: this.i18n.SIDE_MENU.DASHBOARD_ICON,
      name: this.i18n.SIDE_MENU.DASHBOARD,
      route: '',
      disabled: !(this.isConfigured()),
      display: true
    },
    {
      icon: this.i18n.SIDE_MENU.ENCLAVE_STUDIES_ICON,
      name: this.i18n.SIDE_MENU.ENCLAVE_STUDIES,
      route: 'studies',
      disabled: !(this.isConfigured())  ||  this.accessDetails[env.PERMISSIONS.STUDIES_APP] === false,
      display: this.accessDetails[env.PERMISSIONS.STUDIES_APP]
    },
    {
      icon: this.i18n.SIDE_MENU.DATA_ICON,
      name: this.i18n.SIDE_MENU.DATA,
      route: 'catalogue',
      disabled: !(this.isConfigured())  ||  this.accessDetails[env.PERMISSIONS.DATA_CATALOG] === false,
      display: this.accessDetails[env.PERMISSIONS.DATA_CATALOG]
    },
    {
      icon: this.i18n.SIDE_MENU.ENCLAVE_CONF_ICON,
      name: this.i18n.SIDE_MENU.ENCLAVE_CONF,
      route: 'config',
      disabled: this.accessDetails[env.PERMISSIONS.CONF_APP] === false,
      display: this.accessDetails[env.PERMISSIONS.CONF_APP]

    }
  ]

  toggleSideMenu(open: boolean){
    this.menuExpanded = open
  }

  onSelectedChange(e: CustomEvent<(string | number)[]>) {
    this.selectedMenu = e.detail;
  }

  isConfigured(){
    return this.enclaveService.isConfigured()
  }
}
